html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background: transparent; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: none;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: none;
  padding: 0;
  margin: 0; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

@keyframes hippyshake {
  0% {
    transform: translateX(2px); }
  20% {
    transform: translateX(0); }
  40% {
    transform: translateX(-2px); }
  60% {
    transform: translateX(0); }
  80% {
    transform: translateX(2px); }
  100% {
    transform: translateX(0); } }

@keyframes loading {
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    opacity: 0; } }

#home .services-buttons ul:after {
  content: '';
  clear: both;
  display: block;
  width: 100%;
  overflow: hidden; }

.hidden {
  display: none; }

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .loading p {
    color: #fff; }
  .loading .dot {
    width: 14px;
    height: 14px;
    margin: 0 7px;
    background: #fff; }
  .loading p {
    margin: 0;
    text-align: center; }
  .loading .dot {
    display: inline-block;
    transform: scale(0);
    border-radius: 50%;
    animation: loading 1s ease infinite; }
    .loading .dot:nth-child(2) {
      animation: loading 1s ease infinite 0.3s; }
    .loading .dot:nth-child(3) {
      animation: loading 1s ease infinite 0.6s; }

#home h1 {
  font-size: 0.8rem;
  position: relative;
  display: inline-block;
  margin-bottom: 1.5rem;
  padding-bottom: 0; }
  #home h1:before {
    content: ' ';
    display: block;
    width: 100px;
    height: 1px;
    border-bottom: 1px solid #0c286e;
    position: absolute;
    bottom: 0; }
  #home h1:after {
    content: ' ';
    display: block;
    width: 100%;
    height: 1px;
    padding-top: 0.75rem;
    border-bottom: 1px solid rgba(12, 40, 110, 0.4);
    position: absolute;
    bottom: 0; }

#home h1, #home .services-buttons li a span {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700; }

#home .home-testimonials-container .bx-controls {
  width: 100%; }
  #home .home-testimonials-container .bx-controls .bx-controls-direction {
    width: 100%; }
  #home .home-testimonials-container .bx-controls .bx-prev, #home .home-testimonials-container .bx-controls .bx-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 50;
    transform: scale(1, 1.5) translateY(-50%); }
  #home .home-testimonials-container .bx-controls .bx-prev {
    left: 0; }
  #home .home-testimonials-container .bx-controls .bx-next {
    right: 0; }

#home h1 {
  font-size: 1.1rem;
  padding-bottom: 1.5rem; }

#home .hero {
  background-size: cover;
  position: relative; }
  #home .hero .hero-inner {
    height: 600px; }

#home .services-buttons {
  width: calc(100% - 1.5rem * 2);
  position: absolute;
  bottom: -45px; }
  #home .services-buttons .block {
    margin-bottom: 0; }
  #home .services-buttons li {
    position: relative;
    background: #0c286e;
    background: -moz-linear-gradient(left, #0c286e 0%, #092057 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, #0c286e), color-stop(100%, #092057));
    background: -webkit-linear-gradient(left, #0c286e 0%, #0c286e 51%, #092057 51%, #092057 100%);
    background: -o-linear-gradient(left, #0c286e 0%, #092057 100%);
    background: -ms-linear-gradient(left, #0c286e 0%, #092057 100%);
    background: linear-gradient(to right, #0c286e 0%, #092057 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$color1, endColorstr=$color2, GradientType=1 );
    border-bottom: 2px solid #ffd27c; }
    @media only screen and (min-width: 40em) {
      #home .services-buttons li {
        margin: 0 auto;
        width: calc(100%/2); }
        #home .services-buttons li:before {
          content: ' ';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
          background: #6a7ca6;
          background: -moz-linear-gradient(left, #6a7ca6 0%, #092057 100%);
          background: -webkit-gradient(left top, right top, color-stop(0%, #6a7ca6), color-stop(100%, #092057));
          background: -webkit-linear-gradient(left, #6a7ca6 0%, #6a7ca6 51%, #092057 51%, #092057 100%);
          background: -o-linear-gradient(left, #6a7ca6 0%, #092057 100%);
          background: -ms-linear-gradient(left, #6a7ca6 0%, #092057 100%);
          background: linear-gradient(to right, #6a7ca6 0%, #092057 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$color1, endColorstr=$color2, GradientType=1 );
          opacity: 0;
          transition: opacity 0.2s ease-in; }
        #home .services-buttons li:after {
          content: ' ';
          position: absolute;
          left: 0;
          bottom: -4px;
          right: 0;
          width: 100%;
          height: 4px;
          z-index: 0;
          background: #ffd27c;
          background: -moz-linear-gradient(left, #ffd27c 0%, #ffc963 100%);
          background: -webkit-gradient(left top, right top, color-stop(0%, #ffd27c), color-stop(100%, #ffc963));
          background: -webkit-linear-gradient(left, #ffd27c 0%, #ffd27c 51%, #ffc963 51%, #ffc963 100%);
          background: -o-linear-gradient(left, #ffd27c 0%, #ffc963 100%);
          background: -ms-linear-gradient(left, #ffd27c 0%, #ffc963 100%);
          background: linear-gradient(to right, #ffd27c 0%, #ffc963 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$color1, endColorstr=$color2, GradientType=1 );
          opacity: 0;
          transition: all 0.3s ease-in; } }
    @media only screen and (min-width: 70em) {
      #home .services-buttons li {
        margin: 0 auto;
        width: calc(100%/3); } }
    #home .services-buttons li a {
      color: #fff;
      display: block;
      height: 90px;
      text-align: center;
      font-style: italic;
      font-family: 'Cormorant Garamond', serif;
      font-size: 1rem;
      padding: 1.5rem;
      transition: all 0.2s ease-in;
      position: relative;
      z-index: 2; }
      #home .services-buttons li a span {
        display: block;
        font-style: normal;
        font-family: "Nunito Sans", sans-serif;
        font-size: 1.1rem;
        letter-spacing: 2px; }
    #home .services-buttons li:hover a span {
      color: #ffd27c; }
    #home .services-buttons li:hover:before {
      opacity: 1; }
    #home .services-buttons li:hover:after {
      opacity: 1; }

#home .main {
  padding-top: 4.5rem; }

#home .home-testimonials-container {
  background: url("/images/banners/testimonial-bg.jpg") no-repeat center center;
  background-size: cover;
  padding: 3rem 0;
  position: relative; }
  #home .home-testimonials-container blockquote {
    text-align: center;
    margin: 0 3rem;
    padding: 0; }
    #home .home-testimonials-container blockquote p {
      font-size: 1.6rem;
      color: #fff;
      margin-bottom: 1.5rem; }
    #home .home-testimonials-container blockquote cite {
      font-size: 0.9rem;
      font-weight: 700;
      color: #0c286e; }
  #home .home-testimonials-container .block {
    margin-bottom: 0; }
  #home .home-testimonials-container .bx-wrapper {
    position: relative; }
  #home .home-testimonials-container .bx-controls .bx-prev,
  #home .home-testimonials-container .bx-controls .bx-next {
    color: #fff;
    font-size: 2rem; }
  @media only screen and (min-width: 60em) {
    #home .home-testimonials-container blockquote {
      margin: 0 7.5rem; }
      #home .home-testimonials-container blockquote p {
        font-size: 2rem;
        color: #fff; } }

.home-slider-container {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 0; }
  .home-slider-container .block {
    margin-bottom: 0; }
  .home-slider-container .home-banners .home-banner {
    height: 600px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; }
  .home-slider-container .home-banners .slide-0 {
    background-image: url("/images/banners/slide-0.jpg"); }
  .home-slider-container .home-banners .slide-1 {
    background-image: url("/images/banners/slide-1.jpg"); }
  .home-slider-container .home-banners .slide-2 {
    background-image: url("/images/banners/slide-2.jpg"); }
  .home-slider-container .home-banners .slide-3 {
    background-image: url("/images/banners/slide-3.jpg"); }
